import CategoriesItem from "../CategoriesItem";
import Footer from "../Footer";
import Newsletter from "../Newsletter";

function All() {
  return (
    <>
      <CategoriesItem />
      <Newsletter />
    </>
  );
}

export default All;
