import CategoriesItemSkinCare from "./CategoriesItemSkinCare";
import Footer from "../Footer";
import Newsletter from "../Newsletter";

function SkinCare() {
  return (
    <>
      <CategoriesItemSkinCare />
      <Newsletter />
    </>
  );
}

export default SkinCare;
